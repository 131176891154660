<template>
  <div class="contact-wrap">
    <Header :title="'Mask'" :subTitleColor="'#fff'" :sub-title="$t('common.contactUs')" :bgColor="'#000'" />
    <div class="contact-box">
      <div class="contact-content">
        <p>{{ $t('common.officialEmail') }} : maskweb3.io@gmail.com </p> <br/>
        <p>{{ $t('common.officialTg')}} :
          <a href="https://t.me/maskweb3date" target="_blank" style="color: #FD2084;text-decoration: underline;">t.me/maskweb3date</a>
        </p>
      </div>
      <div class="copy-btn" @click="() => $gbUtils.handleCopy('maskweb3.io@gmail.com')">
        {{ $t('common.copy') }}
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  components: { Header },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.contact-wrap {
  background: #000;

  .contact-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: #14151e;
    border-radius: 10px 10px 0 0;

    div {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      margin-top: 100px;
    }

    .copy-btn {
      width: 200px;
      height: 38px;
      border-radius: 5px;
      background: #8A73D0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
  }
}
</style>